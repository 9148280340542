import React from "react"

import { Container } from "react-bootstrap"
import Layout from "components/layout"
import SEO from "components/seo"
import ResultCard from "components/ResultCard/ResultCard"

const ManualEntryPage = ({ location }) => {
    return (
        <div className="background-grey">
          <Layout pageInfo={{ pageName: "results" }}>
            <SEO title="Results" keywords={[`gatsby`, `react`, `bootstrap`]} />
            <Container fluid className="px-0 my-5">
              <ResultCard manualEntry={true} index={0} />
            </Container>
          </Layout>
        </div>
      )
}

export default ManualEntryPage